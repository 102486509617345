import { endpoints } from "./endpoints";
import request from "../request";

//AUTH
export function login(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.login,
      method: "post",
      data,
    })
      .then(async function(response) {
        setUserToken(response);
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function signUpByCode(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.signUpByCode,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error.response.data);
      });
  });
}

export function deleteUserAccount(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteUserAccount,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error.response.data);
      });
  });
}

function setUserToken(token) {
  localStorage.setItem("userToken", token.access_token);
}

export function getUser() {
  return new Promise((resolve) => {
    request({
      url: endpoints.user,
      method: "post",
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

//
export function sendContactMessage(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendContactMessage,
      method: "post",
      data,
    })
      .then(async function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function getPlanes() {
  return new Promise((resolve) => {
    request({
      url: endpoints.getPlanes,
      method: "post",
    })
      .then(function(response) {
        console.log("Planes Ok: ", response);
        resolve(response);
      })
      .catch(function(error) {
        console.log("Planes error: ", error);
        resolve(error);
      });
  });
}

export function getSupervisorByID(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getSupervisorByID,
      method: "post",
      data,
    })
      .then(function(response) {
        console.log("getSupervisorByID Ok: ", response);
        resolve(response);
      })
      .catch(function(error) {
        console.log("getSupervisorByID error: ", error);
        resolve(error);
      });
  });
}

export function test() {
  return new Promise((resolve) => {
    setTimeout(function() {
      resolve(true);
    }, 5000);
  });
}

//SOSCAM

export function createPurchaseOrder(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.createPurchaseOrder,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getCartData(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getCartData,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getToPayData(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getToPayData,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getPlanesPromotions(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getPlanesPromotions,
      method: "get",
      params: data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getProductsInfo(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getProductsInfo,
      method: "get",
      params: data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

//Ruleta

export function getRouletteOptions(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getRouletteOptions,
      method: "get",
      params: data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getRouletteResult(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getRouletteResult,
      method: "get",
      params: data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

// Países Regiones Comunas

export function getPaisesActivos(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getPaisesActivos,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getRegionesActivas(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getRegionesActivas,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getComunasActivas(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getComunasActivas,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getUserDetails(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getUserDetails,
      method: "get",
      params: data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}

export function getOrderStatusByToken(data) {
  return new Promise((resolve) => {
    request({
      url: endpoints.getOrderStatusByToken,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        resolve(error);
      });
  });
}
export function listadoCiudadesDestino (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.listadoCiudadesDestino,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function createPurchaseOrderCouponCam (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createPurchaseOrderCouponCam,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}