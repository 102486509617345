<template>
  <v-app>
	<!-- <Drawer/> -->
    <FullContainer />
  </v-app>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import FullContainer from "./components/Layout/FullContainer";
// import Drawer from "./drawer/Drawer.vue";

export default {
  name: "App",
  components: { FullContainer ,
//    Drawer
    },
  data: () => ({
    //
  }),
  mounted() {
    this.initFirebase();

    const cart = localStorage.getItem('cart');
    if (cart) {
      this.$store.commit('setCart', JSON.parse(cart));
    }
    const cart_plans = localStorage.getItem('cart_plans');
    if (cart_plans) {
      this.$store.commit('setCartPlans', JSON.parse(cart_plans));
    }

    const prize = localStorage.getItem('prize');
    if (prize) {
      this.$store.commit('setRoulettePrize', JSON.parse(prize));
      const discountCode = localStorage.getItem('discountCode')
      this.$store.commit('setDiscountCode', discountCode);
    }
  },  
  methods: {
    initFirebase() {
      const firebaseConfig = {
        apiKey: "AIzaSyC__hpErbrF-l9vP_7mWI8FSXSjqJFAyG0",
        authDomain: "click2call-671d1.firebaseapp.com",
        databaseURL: "https://click2call-671d1-default-rtdb.firebaseio.com",
        projectId: "click2call-671d1",
        storageBucket: "click2call-671d1.appspot.com",
        messagingSenderId: "360045088915",
        appId: "1:360045088915:web:aa0f190408fd668efcfd66",
        measurementId: "G-J9DTZPLG1S",
      };

      //   const firebaseConfig = {
      //   apiKey: "AIzaSyD4yWqis7Qny7gfp5bugquN1o1c1ZCzxBU",
      //   authDomain: "click2call-dev-d9306.firebaseapp.com",
      //   databaseURL: "https://click2call-dev-d9306-default-rtdb.firebaseio.com",
      //   projectId: "click2call-dev-d9306",
      //   storageBucket: "click2call-dev-d9306.appspot.com",
      //   messagingSenderId: "643543297069",
      //   appId: "1:643543297069:web:1edbbfab15cf737cf51ca4",
      //   measurementId: "G-YPR9NQ3VW4"
      // };

      const app = initializeApp(firebaseConfig);
      getAnalytics(app);
    //   const analytics = getAnalytics(app);
    },
  },
};
</script>
<style lang="scss">
.vdialognew {
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
</style>