const endpoints = {
	sendContactMessage: '/contact/sendContactMessage',
	getPlanes: '/app/planes/getPlanes',
	getSupervisorByID: '/auth/getSupervisorByID',

	//BTN
	videollamada: '/api/calls/videoCall',
	videoEmail: '/api/calls/videoMail',
	speciality: '/api/company/specialities',
	callEvent: '/api/events',
	postFormContac: '/api/attention/contact',
	
	//Registro
	register: '/api/register',
	getToken: '/api/token',
	
	//SERVICIOS
	getServices: '/api/company/serviceStatus',
	
	//SOSCAM
	createPurchaseOrder: '/app/pagos/createPurchaseOrder',
	getPaisesActivos: '/auth/getPaisesActivos',
	getRegionesActivas : '/auth/getRegionesActivas',
	getComunasActivas : '/auth/getComunasActivas ',
	user: '/app/user/details',
	getOrderStatusByToken: '/auth/getOrderStatusByToken',
	getCartData: '/app/pagos/getCartData',
	getToPayData: '/app/planes/getToPayData',
	getPlanesPromotions: '/app/planes/getPlanesPromotions',
	

	getProductsInfo: '/app/products/getProductsInfo',
	listadoCiudadesDestino: '/app/pagos/listadoCiudadesDestino',
	createPurchaseOrderCouponCam: '/app/pagos/createPurchaseOrderCouponCam',

	//Ruleta
	getRouletteOptions: '/marketing/getRouletteOptions',
	getRouletteResult: '/marketing/getRouletteResult',

	//AUTH
	login: '/auth/login',
	signUpByCode: '/auth/signUpByCode',
	deleteUserAccount: '/app/user/deleteUserAccount',

	//Cameras
	cameraList: 'admin/tuya/getPanelTuyaCamera',
	getTuyaCamera: '/admin/tuya/getTuyaCameraPublic',
	getTuyaCameraVideoInfo: '/admin/tuya/getTuyaCameraVideoInfo',
	cameraPtzMove: '/admin/tuya/ptzMovePublic',
	getStatusCamera: '/admin/tuya/getStatusCamera',
	commandTuyaDevice: '/admin/tuya/commandTuyaDevice',
}
export {endpoints}
