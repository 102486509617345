<template>
    <main class="Success">

        <section v-if="!loader">
        <div v-if="success" class="Success__Card">
            <div style="display:flex; align-self: flex-start;">
            <v-icon style="font-size: 2rem; margin-right:4px;" large color="green">
                mdi-check-circle
            </v-icon>
            <h2>Compra finalizada</h2>
            </div>
            <p>Recibirás un correo con el detalle de la compra.</p>
            <h4 style="margin-right:auto; margin-top: 20px; margin-bottom: 20px;">Detalle de compra:</h4>

              <v-simple-table style="width:100%;">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td style="padding-left:0;">Producto</td>
                      <td>{{ detail.subject }}</td>
                    </tr>
                    <tr>
                      <td style="padding-left:0;">Monto</td>
                      <td>{{ detail.paymentData.amount }} {{detail.paymentData.currency}}</td>
                    </tr>
                    <tr>
                      <td style="padding-left:0;">Fecha</td>
                      <td>{{ detail.paymentData.date }}</td>
                    </tr>
                    <tr>
                      <td style="padding-left:0;">Medio de pago</td>
                      <td>{{ detail.paymentData.media }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>

            <v-btn @click="$router.push('/')" style="margin-top: 32px;z-index:1;" block>Volver al inicio</v-btn>
        </div>

        <div v-else class="Error__Card">
            <v-icon style="font-size: 4rem;" large color="#D32527">
                mdi-close-circle
            </v-icon>
            <h2>Ocurrió un error</h2>
            <p>No se pudo completar la transacción.</p>
            <v-btn @click="$router.push('/finalizar-compra')" style="margin-top: 16px;z-index:1;" block>Volver</v-btn>
        </div>
        </section>


        <div v-else class="loader"></div>
    </main>
</template>

<script>
import { getOrderStatusByToken } from '../../Helpers/api/general'

export default {
    data () {
        return {
            loader: true,
            success: null,
            detail: null
        }
    },
    mounted() {
        const order_token = this.$route.query.token
        if (order_token) {
            getOrderStatusByToken({ order_token }).then(res => {
                if (res.orderStatus.status == 2) {
                    this.detail = res.orderStatus;
                    this.loader = false;
                    this.success = true

                    localStorage.removeItem("cart_plans");
                    localStorage.removeItem("cart");
                    localStorage.removeItem("qty");

                    const local_orden_token = localStorage.getItem('order_token')
                    if (local_orden_token && local_orden_token !== order_token) {
                        window.ga('send', 'event', 'camera_sale');
                        localStorage.setItem('order_token', order_token)
                    }
                } else {
                    this.loader = false;
                    this.success = false;
                }
        }).catch(error => {
            console.log(error);
            this.loader = false;
            this.success = false;
        })
        }
    },
    
}
</script>

<style lang="scss" scoped>


.Success {
    height: calc(100vh - 76px);
    background-color: #D32527;
    display: flex;
    justify-content: center;
    align-items: center;
    &__Card {
        width: 500px;
        padding: 46px;
        background-color: #FFFFFF;
        border: solid 2px #707070;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 568px) {
    .Success {
        &__Card {
            width: 99%;
            margin: 0 auto;
        }
    }
}

.Error {
    &__Card {
        padding: 20px;
        background-color: #FFFFFF;
        border: solid 2px #707070;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #fff, -20px 0 #fff2;background: #fff }
    33% {box-shadow: 20px 0 #fff, -20px 0 #fff2;background: #fff2}
    66% {box-shadow: 20px 0 #fff2,-20px 0 #fff; background: #fff2}
    100%{box-shadow: 20px 0 #fff2,-20px 0 #fff; background: #fff }
}
</style>