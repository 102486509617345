import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    primaryDB: null,
    derived: null,
    step: null,
    specialityID: null,
    modalButton: 1,
    roomId: null,

    modalVideoState: {
      videoID: "",
      open: false,
    },
    modalContact: false,
    planes: {
      planes: [],
      includes: [],
    },
    roulettePrize: null,
    //cart
    products: [],
    cart_plans: [],
    discountCode: ''


  },
  mutations: {
    setCart(state, products) {
      state.products = products
      const productosJSON = JSON.stringify(products);
      localStorage.setItem('cart', productosJSON);
    },
    setCartPlans(state, plans) {
      state.cart_plans = plans
      const productosJSON = JSON.stringify(plans);
      localStorage.setItem('cart_plans', productosJSON);
      
    },
    setRoulettePrize(state, val) {
      state.roulettePrize = val;
      const prizeJSON = JSON.stringify(val);
      localStorage.setItem('prize', prizeJSON);

    },
    setDiscountCode(state, val) {
      state.discountCode = val;
    },
    setRoomId(state, val) {
      // console.log("setRoomId", val )
      state.roomId = val;
      // this.roomId = val
      // console.log(this.roomId)
    },
    setModalButton(state, val) {
      state.modalButton = val;
      // console.log("estado modal drawer:",val)
    },
    setPrimaryDB(state, value) {
      state.primaryDB = value;
    },
    setDerived(state, val) {
      state.derived = val;
      // console.log("val",val);
    },
    setStep(state, val) {
      state.step = val;
      // console.log("step funciono",val);
    },
    setSpeciality(state, val) {
      state.specialityID = val;
      console.log("Speciality ID", val);
    },
    setModalVideoState(state, modalData) {
      state.modalVideoState = modalData;
    },
    setModalContact(state, modalData) {
      state.modalContact = modalData;
    },
    setPlanes(state, planes) {
      state.planes = planes;
    },
  },
});

export default store;
