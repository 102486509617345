<template>
  <div style="background-color: #FFF;">
    <!--             HERO -->
    <header v-if="!$store.state.roulettePrize" style="padding-top: 80px;" class="v1 relative-box " id="home-area">
      <div class="HeroMessage">
        <p class="HeroMessage__Text">Por la compra de tu plan, te entregaremos una SOSCAM interior totalmente GRATIS.</p>
      </div>

      <div class="image-side-box right-box hidden-xs hidden-sm  box-bg">
        <img
          src="../../assets/phone.png"
          class="layer bg-camara"
          style="width:60%; max-width: 320px;"
          data-depth="0.35"
          alt="SOSclick"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-5">
            <div class="text-box">
              <h2 style="font-weight: 800; font-size: 50px; color:#D32527;">
                SOSCLICK
                <span
                  style="font-weight: 400; display: block; font-size: 20px; color:#D32527;"
                  >Tu Solución Integral de Seguridad</span
                >
              </h2>
              <div class="hr"></div>
              <h3 style="font-weight:800; color:#D32527; margin-top:28px;">
                ¡Bienvenido al Futuro de la Seguridad!
              </h3>
              <p style="max-width: 580px; text-align: justify;">
<!--                 Descubre la revolución en seguridad con SOSCAM, la Cámara de
                Seguridad IP que se integra perfectamente con el innovador
                Sistema de Seguridad SOSClick.  -->Monitorea y protege tu Hogar, Local Comercial,
                Almacén o Pyme desde cualquier lugar a
                través de nuestra potente App SOSClick. Aprovecha la
                oportunidad de garantizar la seguridad de tus seres queridos y
                posesiones más preciadas.
              </p>
<!--               <h3  :class=" price ? 'scale-animation' : 'scale-animation-0'" style="font-size: 24px;margin-top: 8px;  transition: all .7s ease;">
                Ahora <span>${{priceDiscount | formatNumber}}! <span v-if="discount" style="font-size: 20px; text-decoration:line-through;">${{price | formatNumber}}</span></span>
              </h3> -->
<!--               <div v-else class="loader"></div> -->

<!--               <div class="space-30"></div> -->
              <div class="hero-buttons-container mt-2" >
                <button
                  style="z-index:98;"
                  @click="$router.push('/catalogo')"
                  class="main-btn"
                >
                  Comprar
                </button>

                <a href="#info" style="z-index: 1;"><button style="color: #D32527;">Más Información</button></a>
              </div>
                <img src="../../assets/imgs/webpay.png" style="width: 100px;margin-top: 12px;margin-left: 22px;" alt="webpay">
            </div>
          </div>
        </div>
      </div>
    </header>
    <SOSCamPrizeHeroVue v-else />

    <!--         POR QUE SOSCAM -->
    <div id="info" class="container" style="margin: 0 auto; scroll-margin-top: 90px;">
      <section class="Porque">
        <h2 class="Porque__Title">¿por qué sosclick?</h2>

        <div class="Porque__Container" style="background-color: #F7F7F7;">
          <div
            class="Porque__Container__Item"
            v-for="(item, i) in features"
            :key="i"
          >
            <div class="container-title-item">
              <h3 class="Porque__Container__Item__Number">{{ item.number }}</h3>
              <v-icon
                v-if="item.number !== 8"
                class="Porque__Container__Item__Icon"
                >{{ item.icon }}</v-icon
              >
              <div v-else style="display:flex; align-items: center;">
                <v-icon class="Porque__Container__Item__Icon">mdi-apple</v-icon>
                <v-icon class="Porque__Container__Item__Icon"
                  >mdi-android</v-icon
                >
              </div>
            </div>

            <h4 class="Porque__Container__Item__Title">{{ item.title }}</h4>
            <p class="Porque__Container__Item__Paragraph">
              {{ item.paragraph }}
            </p>
          </div>

          <div class="Porque__Container__Item Porque__Container__Item--button" style="display: flex; align-items: center;flex-direction: column;">
            <button style="z-index: 98;" class="main-btn"  @click="$router.push('/catalogo')">Comprar</button>
                <img src="../../assets/imgs/webpay.png" style="width: 100px;margin-top: 12px;" alt="webpay">
          </div>
        </div>
      </section>
    </div>

    <!--         OFERTA -->
    <section
      class="intro-area about-section over-hidden oferta-bg"
      id="about-area"
      style="margin-top: 20px;"
    >
      <div class="container oferta-container">
        <div class="Oferta">
          <div style="position:relative;">
            <figure class="play-image wow zoomIn">
              <img
                src="../../assets/phone.png"
                class="bg-camara Oferta__Img"
                alt=""
              />
            </figure>
            <a
              href="https://www.youtube.com/watch?v=jPmd-9Arc2c"
              data-lity
              class="play-bttn"
              style="z-index: 98;"
              ><i class="fa fa-play"></i
            ></a>
          </div>

          <div class="text-box text-white  Oferta__Content">
            <h2 class="title">
              OFERTA EXCLUSIVA <br />
              ¡PROTEGE LO QUE MÁS QUIERES<!--  POR SOLO ${{priceDiscount | formatNumber}}! <span style="font-size: 20px; text-decoration:line-through;" v-if="discount">${{price | formatNumber}}</span> -->
            </h2>
            <p>
              Aprovecha nuestra oferta especial por tiempo limitado y llévate la
              avanzada tecnología de SOSCLICK. La seguridad no
              puede esperar, ¡compra ahora y transforma la forma en que proteges
              tu mundo!
            </p>
            <div class="space-40"></div>

            <div style="display: flex; margin: 0 auto; align-items: center; justify-content: center; flex-direction: column;">
            <button
              style="z-index: 98;position:relative;"
              @click="$router.push('/catalogo')"
              class="main-btn"
            >
              Comprar
            </button>
                <img src="../../assets/imgs/webpay.png" style="width: 100px;margin-top: 12px;" alt="webpay">
            </div>
            
          </div>
        </div>
      </div>
    </section>

    <!-- CÁMARA VIDEO -->
<!--     <section  style="margin:0 auto">
      <h2 class="Beneficios__Text__Title text-center py-5">SOSCAM En Directo</h2>
      <div>
        <CameraView
          v-if="cameraData && socket"
          :ref="cameraData.deviceId"
          :cameraData="cameraData"
          :socket="socket"
          :main="false"
        />
        <div v-else style="position:relative; display:flex;justify-content:center; max-width: 1000px;margin: 0 auto;">
          <img style="z-index: 98; cursor:pointer; width:100%" @click="startVideo" :src="require('../../assets/imgs/default-camera.jpg')">
          <v-icon @click="startVideo"  style="z-index: 98; cursor:pointer;font-size: 100px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">mdi-play-circle-outline</v-icon>
        </div>
      </div>
    </section> -->

    <!--         BENEFICIOS -->

    <div class="container containerbeneficios" style="position:relative;" >
      <section class="Beneficios">
        <img
          class="Beneficios__Img"
          src="../../assets/Camara.png"
          alt="soscam"
        />

        <div class="Beneficios__Text">
          <h2 class="Beneficios__Text__Title">BENEFICIOS ADICIONALES</h2>
<!--           <p class="Beneficios__Text__Paragraph">
            <span style="font-weight: 800;">Envío Gratuito:</span> Adquiere tu SOSCAM hoy y disfruta del envío gratuito
            a tu puerta.
          </p> -->
            <p class="Beneficios__Text__Paragraph">
            <span style="font-weight: 800;">Garantía de Satisfacción:</span> Compra con confianza gracias
            a nuestra garantía de satisfacción.
          </p>
          <p class="Beneficios__Text__Paragraph">
            <span style="font-weight: 800;">Soporte Técnico:</span> Nuestro equipo de soporte técnico está
            para ayudarte cuando lo necesites a través de los canales disponibles.
          </p>
        </div>
      </section>
    </div>

    <!--             PROTEGE TU MUNDO -->

    <section class="Protege"  style="background-color:#F7F7F7; ">
      <div class="container">
        <div class="Protege__Title">
          <h2 class="Protege__Title__Text">SOSCLICK</h2>
<!--           <h3 class="Protege__Title__Price">
            Ahora <span class="Protege__Title__Price--number">${{priceDiscount | formatNumber}}! <span style="font-size: 20px; text-decoration:line-through;" v-if="discount">${{price | formatNumber}}</span></span>
          </h3> -->
        </div>

        <div class="Protege__Content">
          <h4 class="Protege__Content__Title">¡PROTEGE TU MUNDO CON SOSCLICK HOY!</h4>
          <p class="Protege__Content__Paragraph">
            No dejes pasar esta oportunidad única. La seguridad no puede
            esperar, y con SOSCLICK, tu tranquilidad está a solo un click de
            distancia. Haz clic en “Comprar Ahora” y asegura tu SOSCAM. ¡Transforma la forma en que proteges lo que más amas!
          </p>

        <div style="display: flex; flex-direction: column; justify-content: center; align-items:center;">
          <button style="z-index: 98;position:relative;" class="main-btn Protege__Content__Button"  @click="$router.push('catalogo')">Comprar</button>
          <img src="../../assets/imgs/webpay.png" style="width: 100px;margin-top: 12px;" alt="webpay">
        </div>

        </div>
      </div>
    </section>


<!--                   FIXED SOSCAM -->
<!--     <div  class="FixedCam" @click="dialogWheel = true" v-if="!$store.state.roulettePrize">
    <div  class="FixedCam">
      <img class="FixedCam__Img" src="../../assets/Camara.png" style="max-width: 420px;" alt="SOSCAM"/>

      <div class="FixedCam__Text">
      <h5 class="FixedCam__Text__Title">GÁNATE <br /> UNA SOSCAM</h5>
      <p class="FixedCam__Text__Subtitle">Tu Solución Integral de Seguridad</p>
      </div>
    </div>
    </div> -->
<!--     <v-dialog :width="width < 568 ? 'auto' : 'unset'" :content-class="width < 568 ? 'vdialognew' : ''"  v-model="dialogWheel" v-if="dialogWheel" > 
      <Wheel :product_id="product_data.product_id" :closeRoulette="closeRoulette"/>
    </v-dialog> -->
  </div>
</template>

<script>
//import Wheel from '../Modals/Wheel.vue'
import SOSCamPrizeHeroVue from '../StaticPages/SOSCamPrizeHero.vue';
import { getCartData } from '../../Helpers/api/general'
/* import CameraView from '../Home/CameraView.vue' */
import { getTuyaCamera } from '../../Helpers/api/cameras'
import io from 'socket.io-client';


export default {
  components: { /* Wheel, */ SOSCamPrizeHeroVue, /* CameraView */ },
  data() {
    return {
      socket: null,
      socketUrl: 'https://devapis.anubys.cl:3000',
      cameraData: {},
      product_data: {months: null, id: 2},
      features: [
        {
          number: 1,
          icon: "mdi-video",
          title: "Alerta en Red",
          paragraph:
            "Crea tu propia de Red de Seguridad con hasta 5 contactos para que puedas compartir tus cámaras de seguridad y sean alertados en caso de activar el botón de pánico."
        },
        {
          number: 2,
          icon: "mdi-microphone",
          title: "Botón de Pánico",
          paragraph:
            "actívalo con un toque o comando de voz para iniciar una videollamada instantánea con tu red de seguridad",
        },
        {
          number: 3,
          icon: "mdi-cursor-move",
          title: "Localización en Tiempo Real",
          paragraph:
            "Monitorea tu ubicación y mantente conectado con tu Red de Seguridad",
        },
        {
          number: 4,
          icon: "mdi-run",
          title: "Transmisión en Vivo y Almacenamiento Seguro",
          paragraph:
            "Comparte imágenes en tiempo real, todas las alertas quedan registradas en nuestros servidores.",
        },
        {
          number: 5,
          icon: "mdi-eye",
          title: "Cámaras Wifi de Fácil Instalación",
          paragraph:
            "Conecta a través de WiFi e instala la aplicación de manera sencilla para tener el control total de tu seguridad.",
        },
        {
          number: 6,
          icon: "mdi-chat-outline",
          title: "Control Total",
          paragraph:
            "Vigila, escucha y habla desde tus cámaras en cualquier lugar a través de la aplicación.",
        },
        {
          number: 7,
          icon: "mdi-memory",
          title: "Integración Completa",
          paragraph:
            "Recibe alertas, comunicate por videollamadas y gestiona desde un solo lugar todo tu sistema de seguridad.",
        },
        {
          number: 8,
          icon: "mdi-video",
          title: "Vigilancia en Todo Lugar",
          paragraph:
            "Contamos con cámaras de interior y exterior para que tu tranquilidad este asegurada.",
        },
      ],
      dialogWheel: false,
      logged: false,
      price: '',
      priceDiscount: '',
      discount: null
    };
  },
  computed: {
    width() {
      return window.screen.width
    }
  },
  methods: {
    addToCart() {
      const productIndex = this.$store.state.products.findIndex(product => product.product_id === this.product_data.product_id);
      if (productIndex  === -1) {
        this.$store.commit('setCart', [...this.$store.state.products, this.product_data])
      } else {
        const arr = this.$store.state.products
        arr[productIndex].qty = 1;
        this.$store.commit('setCart', arr)
    }
    setTimeout(() => {
      //loader
      this.$router.push({ name: 'SOSCamSingle' })
    }, .5);
    },
    getTuyaCamera() {
      const data = {camera_id: 'ebf269c13f17edd1dc2ymc'}
        getTuyaCamera(data).then(res => {
          this.cameraData = res.connections
/*           this.startSocket(res.connections) */
      })
	},
    closeRoulette() {
      this.dialogWheel = false;
    },
    getProductPrice() {
      const plans = [this.product_data]
      const data =  {plans, coupon_code: this.$store.state.discountCode}
      this.discount = true
      if (!this.$store.state.discountCode) {
        delete data.coupon_code
        this.discount = false
      }

            getCartData(data).then(res => {
              this.priceDiscount = res?.cart_data?.to_pay
              this.price = res?.cart_data?.total
          }).catch(function (error) {
            this.loadingbtn = false
            console.log(error);
            });
    },
    startVideo() {
      this.getTuyaCamera();
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    this.getProductPrice()

        const userToken = localStorage.getItem('userToken')
    if (userToken) {
      this.logged = true;
      } else {
        this.logged = false;
        }
  },
  onBeforeUnmount() {
    this.socket?.disconnect();
  },
  watch:{
    'cameraData': function() {
      console.log('connect socket');
      
      this.socket = io(this.socketUrl, {
        transports: ["websocket"],
        autoConnect: true,
        reconnect: true,
        forceNew: true,
      });
    },
    },
    filters: {
    formatNumber: function(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>

<style lang="scss" scoped>
/*     @import '../../assets/css/style.css';  */
@import "../../assets/css/normalize.css";
/*     @import '../../assets/css/bootstrap-min.css'; */
@import "../../assets/css/owl-carousel-min.css";
@import "../../assets/css/lity-min.css";
@import "../../assets/css/font-awesome-min.css";
@import "../../assets/css/animate.css";
@import "../../assets/css/theme.css";
@import "../../assets/css/helper.css";
@import "../../assets/css/responsive.css";

.title,
.rate {
  color: #d32527;
}
.bttn-1 {
  color: #fff;
  &:hover {
    color: #d32527;
  }
}
.bttn-3 {
  color: #d32527;
  &:hover {
    color: #fff;
  }
}

.bg-camara {
  background-image: url("../../assets/imgs/circle-bg-2.png");
  background-size: cover;
}

.about-section {
  background-image: url("../../assets/image.png");
}
.bttn-2:hover {
  color: #d32527 !important;
}
.top-title {
  font-size: 32px;
  color: rgb(46, 0, 0);
}
.title {
  font-size: 36px !important;
}
@media (max-width: 568px) {
  .intro-area {
    background-size: cover !important;
  }
  .top-title {
    color: #fff;
  }
}

.box-bg {
  background-image: url("../../assets/image-box-bg-2.png");
  background-size: cover;
}
///////////////////////////////////////////////////////////////////////////
//hero
.hero-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
}
@media (min-width: 768px) {
  .hero-buttons-container {
    flex-direction: row;
  }
}

//POR QUÉ
//mobile
.Porque {
    padding-top: 20px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff;
  border-radius: 28px;
  opacity: 1;
  /* backdrop-filter: blur(50px);
-webkit-backdrop-filter: blur(50px); */
  padding-bottom: 20px;
  &__Title {
    text-transform: uppercase;
    text-align: center;
    color: #d32527;
  }
  &__Container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    &__Item {
      text-align: center;
      &__Number {
        color: #d32527;
        font-weight: 800;
        font-size: 46px;
      }
      &__Icon {
        color: #d32527;
        font-size: 56px;
        margin-left: 8px;
      }
      &__Title {
        color: #d32527;
      }
      &__Paragraph {
        color: #000;
      }
    }
  }
}
.container-title-item {
  display: flex;
  justify-content: center;
}

//desktop
@media (min-width: 768px) {
  .Porque {
    padding-top: 0;
    &__Title {
      margin-top: 40px;
      font-size: 32px;
      font-weight: 800;
    }
    &__Container {
      margin-top: 40px;
      justify-content: center;
      gap: 20px;
      &__Item {
        text-align: left;
        width: 340px;
        &--button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &__Number {
        }
        &__Icon {
          font-size: 46px;
        }
        &__Title {
        }
        &__Paragraph {
        }
      }
    }
  }
  .container-title-item {
    justify-content: flex-start;
  }
}

//OFERTA
.oferta-bg {
  background-size: 1000% 100% !important;
}

.Oferta {
  &__Img {
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__Content {
    text-align: center;
  }
}

@media (min-width: 768px) {
    .Oferta {
        &__Content  p{
        margin: 0 auto;
        max-width: 740px;
        }
    }

  .oferta-bg {
    margin-top: 80px;
    background-size: 170% 150% !important;
  }
  .oferta-container {
    padding-bottom: 100px;
  }
}

.play-bttn {
  position: absolute;
  transform: translate(50%, 50%);
  top: 21%;
  left: 40%;
  color: #fff;
  font-size: 60px;
}

@media (min-width: 768px) {
  .play-bttn {
    left: 47%;
  }
}

// BENEFICIOS

.Beneficios {
  margin-top: 60px;
  text-align: center;
  padding: 0 20px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  border-radius: 62px;
  &__Text {
    &__Title {
      color: #d32527;
    }
  }
  &__Img {
    max-width: 250px;
  }
}

@media (min-width: 768px) {
  .Beneficios {
    position: absolute;
   /* bottom: -180px;*/
    display: flex;
    align-items: center;
    &__Text {
      width: 60%;
      text-align: left;
      &__Title {
      }
      &__Paragraph {
        margin-top: 12px;
      }
    }

    &__Img {
    }
  }
}
@media (min-width: 768px) {
    .containerbeneficios {
        height: 220px;
    }
    }


// PROTEGE TU MUNDO

.Protege {
    margin-top: 20px;
    margin-bottom: 20px;
    &__Title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &__Text {
            color: #d32527;
            font-size: 48px;
            line-height: 1;
        }
    &__Price {
        color: #838383;
        font-size: 24px;
    &--number {
        color: #25C917;
        font-size: 28px;
        font-weight: 300;
    }
    }
    }
    &__Content {
        &__Title {
            color: #d32527;
        }
    }
}

@media (min-width: 768px) {
.Protege {
    padding-top: 220px;
    margin-top: 40px;
    display: flex;
    &__Title {
        margin: 0 auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        gap: 20px;
        &__Text {
            font-size: 72px;
        }
    &__Price {
        font-size: 40px;
        font-weight: 400;
    &--number {
        font-size: 60px;
    }
    }
    }
        &__Content {
        text-align: center;
        padding: 32px;
        max-width: 750px;
        margin: 0 auto;
        &__Title {
            font-size: 32px;
        }
        &__Paragraph {
            margin-top: 20px;
            color: #555454;
        }
        &__Button {
            margin-top: 20px;
        }
    }
}
}


.main-btn {
  background-color: #d32527;
  border-radius: 25px;
  color: #fff;
  padding: 12px 50px;
  box-shadow: 0px 3px 6px #00000029;
  &:hover {
    background-color: #fff;
    color: #d32527;
  }
}

@media (min-width: 768px) {
  .main-btn {
    height: max-content;
  }
}

.hr {
    border: 3px solid #BFC0C0; width: 194px;
    margin-left: 20px;
}
@media (min-width: 768px) {
    .hr {
        margin-left: 0;
    }
}

//GANATE UNA SOSCAM FIXED

.FixedCam {
  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 2px 3px 30px 0px #FCF3B0;
-moz-box-shadow: 2px 3px 30px 0px #FCF3B0;
box-shadow: 2px 3px 30px 0px #FCF3B0;
  }
  width: 120px;
  height: 150px;
  position: fixed;
  z-index: 123;
  top: 100px;
  right: 10px;
  background: transparent linear-gradient(180deg, #E6B54C 0%, #FFFDC1 59%, #B58910 100%, #FFFF83 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 29px;
  &__Img {
    position: absolute;
    top: -15px;
    transform: translate(50%, 0);
    right: 50%;
    width: 82%;
  }
  &__Text {
      margin-top: 80%;
    &__Title {
      color: #796843;
      text-align: center;
      font-size: 14px;
    } 
    &__Subtitle {
      display: none;
      color: #766948;
    }
  }
}

@media (min-width: 768px) {
  .FixedCam {
    top: auto;
    bottom: 40px;
    right: 32px;
    width: 205px;
    height: 280px;
/* height: 240px; */
  &__Img {
    top: -30px;
    width: 160px;
    height: 180px;
  }
  &__Text {
      margin-top: 75%;
    &__Title {
      font-size: 18px;
    } 
    &__Subtitle {
      display: block;
      text-align: center;
    }
  }
}
}

.HeroMessage {
    z-index: 1;
  position: absolute;
  top: 20px;
  right: 50%;
  transform: translate(50%, 0);
  background-color: #DF073C;
  color: #FFF;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  text-align: center;
  font-weight: 500;
  & p {
    margin-bottom: 0;
  }
  }

  @media (max-width: 920px) {
    .HeroMessage {
      display: none;
    }
  }


.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
.scale-animation {
  transform: scale(1);
}
.scale-animation-0 {
    transform: scale(0);
}
</style>
